import React from "react";
import AddCandidatePage from "../add-candidate";

function EditCandidatePage() {
  return (
    <div>
      <AddCandidatePage />
    </div>
  );
}

export default EditCandidatePage;
